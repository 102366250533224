// Positions & Alignments
.center {
	display:block;
	position:relative;
	margin-left:auto;
	margin-right:auto;
}

.text-center {
	text-align:center;
}
 
.left {
	float:left;
}

.text-left {
	text-align:left;
}

.right {
	float:right;
}
.text-right {
	text-align:right;
}