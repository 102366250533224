// Tags

$tag_bg_color: darken($bg_color, 3%);
$tag_border_color: darken($border_color, 5%);
$tag_fg_color: lighten($fg_color, 10%);
$tag_height: 24px;

.tags {
	display: block;
	margin: 8px 0;
	width: 100%;
	height: $tag_height + 6px;
}

.tag {
	background-color: $tag_bg_color;
	border-radius:3px;
	border: 1px solid $tag_border_color;
	box-shadow: inset 0 0 0 0 rgba(0,0,0,0.2), inset 0 0 0 1px rgba(255,255,255,0.1);
	color: $tag_fg_color;
	display:inline-block;
	font-size: $font_size - 3px;
	font-weight:bold;
	// font-family: $monospace;
	height: $tag_height;
	line-height:$tag_height;
	margin-right:6px;
	opacity: 1.0;
	outline: none;
	padding:0 6px;
	position:relative;
	text-decoration: none;
	text-shadow:0 1px rgba(255,255,255,0.4);
	text-transform: uppercase;
	vertical-align: top;

	&:focus,
	&:hover {
		box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0.2), inset 0 -1px 0 1px rgba(255,255,255,0.4);
		color: $tag_fg_color;
		height: $tag_height + 1px;
		margin-bottom: 0px;
		top: -1px;
	}

	&:active {
		box-shadow: inset 0 0 0 1px rgba(255,255,255,0.1);
		height: $tag_height;
		margin-bottom: 1px;
		top:0;
	}
}

