// Columns 

.col {
	vertical-align:top;
	display: inline-block;
	height: auto;
	padding-top: 1em;
	padding-bottom: 1em;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;

	// Half
	&.half {
		width: 47%;
		display: inline-block;
		&.left { margin-right: 2%;}
		&.right { margin-left: 2%;}
	}

	// Third
	&.third {
		width: 30%;
		margin: 0 1%;
		&.left { margin-right: 2%;}
		&.right { margin-left: 2%;}
	}

	// Third
	&.two-thirds {
		width: 60%;
		margin: 0 1%;
		&.left { margin-right: 2%;}
		&.right { margin-left: 2%;}
	}

	// Quarted
	&.quarter {
		float: left;
		margin: 0 1%;
		width: 23%;
	}
}

@media only screen and (max-width:1200px) {
	.col {
		&.third:nth-child(1) {
			width: 60%;
			margin: 0 auto;
			float: none;
		}
		&.third:nth-child(2),
		&.third:nth-child(3){
			width: 48%;
			margin-left: 0;
			margin-right: 0;
			display: inline-block;
		}
	}
}	

@media only screen and (max-width:1024px) {
	.col {
		&.third:nth-child(1),
		&.third:nth-child(2),
		&.third:nth-child(3),
		&.half,
		&.two-thirds,
		&.third {
			display: block;
			width: 100%;
			float: none;
			&.left,&.right {float:none;margin:1em 0;}
		}
	}
}
