// Custom Text Classes

// monospace 
.monospace {
	font-family:$monospace;
}
// serif 
.serif {
	font-family:$serif;
}

// title {
.title {
}


// Footnote 
.footnote {
	display:inline-block;
	font-family:$monospace;
	font-size:$font_size * 0.8;
	margin:8px auto;
	width:100%;
	opacity: 0.7;
	> a {
		font: inherit;
	}
}

// Published
.published {
	color:lighten($fg_color, 30%);
	display:block;
	font-family:$monospace;
	margin:16px auto;
	opacity:0.8;
	text-transform:uppercase;
	width:100%;

	.date {
		color:darken($fg_color, 20%);
	}
	.author {
		font: inherit !important;
		color:darken($fg_color, 20%) !important;
	}
}