// BEGINNING OF STYLESHEET

$carousel_control_height: 96px;
$carousel_control_padding: 32px;

// Carousel
.carousel {
	display:block;
	width: 100%;
	height: auto;
	position: relative;
	margin: 2em auto;
}

// Inner Carousel
.carousel-images {
	display:inline-block;
	position: relative;
	width: 100%;
	height: auto;
	overflow: hidden;
	@include transition(200ms);

	// Carousel Item
	.item {
		width: 100%;
		position: relative;
		display: none;
		@include transition(200ms);

		// Make item visible
		&.active,
		&.next,
		&.prev {
			display: block;
		}

		// Next item
		&.next {
			position: absolute;
			transform: translateX(100%);
		}

		// Previous item
		&.prev {
			position: absolute;
			transform: translateX(-100%);
		}

		> * {
			margin:  auto !important;
		}
	}
}
// Controls

.carousel-controls {
	height: $carousel_control_height;
	position: absolute;
	width: 100%;
	top: calc(50% - (#{$carousel_control_height} * 0.5));
}

.carousel-control {
	position: absolute;
	margin-top: calc(#{$carousel_control_padding} * -1);
	padding: $carousel_control_padding 1em;
	border-radius: 8px;

	> svg {fill:$fg_color;}

	&:focus,
	&:active {
		@include transition(200ms);
		> svg {fill:black;}
	}

	&:hover {
		@include transition(200ms);
		> svg {fill:$link_color;}
	}
	&.prev {left: 0;}
	&.next {right: 0;}
}

// Caption
.carousel-caption {
	display: block;
	width: 80%;
	margin: 0 10%;
	margin-top: 24px;
	text-transform:uppercase;
	text-align: center;
	font-size:90%;
	opacity:0.5;
}