// Infobars

$info_color:$blue_color;
$warning_color:$orange_color;
$error_color:$red_color;

// Infobars

$infobar_bg_color:$base_color;
$infobar_border_radius: 7px;
$infobar_font_size: 14px;

%infobar,
.infobar {
	background-color: $infobar_bg_color;
	border-radius: $infobar_border_radius;
	color:$fg_color;
	display: inline-block;
	font-size:$infobar_font_size;
	font-family: $monospace;
	margin: 12px auto;
	padding: 8px 5%;
	text-align: center;
	width: 90%;
	box-shadow:inset 0 0 0 1px $border_color;

	* {
		font-family: inherit;
		font-size: inherit;
		color: inherit;
	}

	span {
		display: block;
		width: 100%;
	}

	&.small {
		border-radius: $infobar_border_radius - 2px;
		padding: 6px 3%;
		font-size:$infobar_font_size - 2px;
	}

	&.row {
		width: 100%;
		margin: 0;
		border-radius: 0;
		padding: 12px 0;
		box-shadow:none;
		border-bottom:1px solid $border_color;
		z-index: 20;
	}

	a {
		font-family:inherit;
		font-size:inherit;
		font-weight:inherit;
		color:$link_color;
	}

	&.error {
		box-shadow:inset 0 0 0 1px transparentize($error_color, 0.6);
		color: $error_color;
	}
	&.info {
		box-shadow:inset 0 0 0 1px transparentize($info_color, 0.6);
		color: $info_color;
	}
	&.warning{
		box-shadow:inset 0 0 0 1px transparentize($warning_color, 0.6);
		color: $warning_color;
	}

	&.coloured {
		color:$highlight_fg_color;
		box-shadow:none;
		border:none;
		font-weight: bold;
		span a {color:inherit;}
		&.error { background-color: $error_color;}
		&.info { background-color: $info_color;}
		&.warning{ background-color: $warning_color;}
	}

}

@media only screen and (max-width:600px) {
	.infobar.sticky {
		position:relative;
		top:0;
	}
}