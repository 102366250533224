.top-arrow {
	border-radius: 1em;
	display: block;
	height: 48px;
	pointer: hand;
	text-align:center;
	width: 100%;
	margin: 1em auto;

	> svg {
		height: 48px;
		fill:darken($bg_color, 20%);
		@include transition(200ms);
	}

	&:focus,
	&:hover {
		background-color:transparentize($link_color, 0.9);
		@include transition(200ms);

		> svg {
			fill:$link_color;
			@include transition(200ms);
		}
	}
}
