$face_size: 300px;
$face_img: url('/img/face.jpg');

#face {
	display: block;
	margin: 2em auto;
	height:$face_size;
	width:$face_size;
	background-image:$face_img;
	background-position:center;
	background-repeat:no-repeat;
	background-size:100%;
	border-radius:50%;
	box-shadow: inset 0 1px 2px 0 rgba(0,0,0,0.2),inset 0 0 0 1px rgba(0,0,0,0.1), 0 1px 0 0 #fff;
}

.pronouns {
	display: block;
	margin-top: 8px;
	font-size: 40%;
	font-weight: normal;
	opacity: 0.4;
}

// MEDIA QUERIES

@media only screen and (max-width:848px) {

	#face {
		margin: 4em auto;
		max-width:95%;
		display: block;
	}
}