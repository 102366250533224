// Buttons

$button_height: 34px;
$button_width: 160px;
$button_font_size: 15px;
$button_fg_color: lighten($fg_color, 30%);
$button_bg_color: $base_color_light;

// Hover
$button_hover_bg_color: $link_color;
$button_hover_fg_color: $button_bg_color;

// Active
$button_active_bg_color: mix($button_bg_color, #000, 90%);
$button_active_fg_color: $button_fg_color;

// Focus
$button_focus_bg_color: $button_bg_color;
$button_focus_fg_color: $fg_color;

// suggested button
$suggested_button_fg_color: $selection_fg_color;
$suggested_button_bg_color: $pink_color;

$suggested_button_hover_bg_color: lighten($suggested_button_bg_color, 5%);
$suggested_button_focus_bg_color: $suggested_button_hover_bg_color;
$suggested_button_active_bg_color: mix($suggested_button_bg_color, #000, 90%);

// submit button
$submit_button_fg_color: $selection_fg_color;
$submit_button_bg_color: $blue_color;

$submit_button_hover_bg_color: lighten($submit_button_bg_color, 5%);
$submit_button_focus_bg_color: $submit_button_hover_bg_color;
$submit_button_active_bg_color: mix($submit_button_bg_color, #000, 90%);

// reset button
$reset_button_fg_color: $selection_fg_color;
$reset_button_bg_color: $red_color;

$reset_button_hover_bg_color: lighten($reset_button_bg_color, 5%);
$reset_button_focus_bg_color: $reset_button_hover_bg_color;
$reset_button_active_bg_color: mix($reset_button_bg_color, #000, 90%);

// text
%button_text {
	color: $button_fg_color;
	font-weight: 500;
	font-size: $button_font_size;
	line-height: $button_height - 1px;
	text-align:center;
	text-decoration:none;
	text-shadow: none;
}


// button class links
%button,
.button {

	@extend %button_text;

	@include border-radius($button_height * 0.5);

	background: $button_bg_color;
	border: none;
	box-shadow:0 1px 1px 0 rgba(0,0,0,0.1), inset 0 0 0 1px rgba(0,0,0,0.15);
	display: inline-block;
	cursor: pointer;
	height: $button_height;
	padding: 0 14px;
	margin: 12px 0;
	outline: none;
	overflow: visible;
	vertical-align:middle;
	// padding: 0 calc(#{$button_width} / 4);
	// @include transition(200ms);

	&.flat {
		background: transparent;
		border: none;
		box-shadow:none;
	}

	&.circular {
		padding: 0;
		width: $button_height;
		height: $button_height;
		border-radius: 50%;
		> svg {
			margin: 9px;
		}
	}

	> svg {
		height: 16px;
		width: 16px;
		display: inline-block;
		float: left;
		margin-top: 8px;
		margin-right: 6px;
		margin-left: 12px;

		> g,
		> path {
			fill:$button_fg_color; 
		}
	}

	> i {
		color:inherit;
		margin-left: 2px;
		margin-right: 6px;
	}

	&:hover,
	&:focus:hover {
		background: $button_hover_bg_color;
		color: $button_hover_fg_color;
		box-shadow:0 1px 1px 0 transparentize($button_hover_bg_color, 0.8), inset 0 0 0 1px rgba(0,0,0,0.15);
		svg > g,
		svg > path {
			fill: $button_hover_fg_color; 
		}
		// @include transition(200ms);
	}

	&:focus {
		background: $button_focus_bg_color;
		color: $button_focus_fg_color;
		text-decoration: underline !important;
		box-shadow:0 1px 1px 0 transparentize($button_hover_bg_color, 0.8), inset 0 0 0 1px rgba(0,0,0,0.15);
		svg > g,
		svg > path {
			fill: $button_focus_fg_color; 
		}
	}

	&:active,
	&:active:focus,
	&:active:hover {
		background: $button_active_bg_color;
		color: $button_active_fg_color;
		svg > g,
		svg > path {
			fill: $button_active_fg_color; 
		}
	}

	&.center {
		display:block;
		min-width: $button_width;
		width: auto;
		max-width: $button_width * 1.5;
		margin: 12px auto;
	}

	&.no-label {
		padding:0;
		> svg{margin: 8px;}
	}

	&.with-icon {
		padding-left:0;
	}
}



// button element
button {
	@extend %button;
	display: block;
}

// coloured button
%coloured-button {
	@extend %button;
	color:$base_color;

	&:hover,
	&:focus{
		opacity:0.8;
	}

	&:active,
	&:active:hover{
		opacity:0.7;
	}
}

// share button
.share {
	@extend %coloured-button;
	text-align: center;
	// compensate for the icon
	padding-right: 18px;

	// icon
	> i {
		width: 18px;
		color:inherit;
		padding-right: 6px;
		line-height:$button_height;
	}

	&.googleplus {background-color: #DD4B39;&:active{background-color:#DD4B39;}}
	&.tweet {background-color: #55ACEE;&:active{background-color:#55ACEE;}}
	&.facebook {background-color: #3B5998;&:active{background-color:#3B5998;}}
}

// suggested button
.suggested {
	@extend %coloured-button;
	background: $suggested_button_bg_color;
	color: $suggested_button_fg_color;

	&:active,
	&:active:hover {
		background: $suggested_button_active_bg_color;
		color: $suggested_button_fg_color;
	}

	&:focus {
		background: $suggested_button_focus_bg_color;
		color: $suggested_button_fg_color;
	}

	&:hover {
		background: $suggested_button_hover_bg_color;
		color: $suggested_button_fg_color;
	}
}
// submit button
input[type=submit]{
	@extend %coloured-button;
	background: $submit_button_bg_color;
	color: $submit_button_fg_color;
	display: block;

	&:active,
	&:active:hover {
		background: $submit_button_active_bg_color;
		color: $submit_button_fg_color;
	}

	&:focus {
		background: $submit_button_focus_bg_color;
		color: $submit_button_fg_color;
	}

	&:hover {
		background: $submit_button_hover_bg_color;
		color: $submit_button_fg_color;
	}
}

// reset button
input[type=reset] {
	@extend %coloured-button;
	background: $reset_button_bg_color;
	color: $reset_button_fg_color;
	display: block;

	&:active,
	&:active:hover {
		background: $reset_button_active_bg_color;
		color: $reset_button_fg_color;
	}

	&:focus {
		background: $reset_button_focus_bg_color;
		color: $reset_button_fg_color;
	}

	&:hover {
		background: $reset_button_hover_bg_color;
		color: $reset_button_fg_color;
	}
}

// button list
ul.button-list {
	display:inline-block;
	list-style:none;
	max-width:100%;
	margin:12px 0%;
	li {
		display:inline-block;
		> a {
			@extend %button;
			display:inline-block;
			min-width:auto;
			width:auto;
			margin:1px;
			text-align:left;
		}
	}
}


// media queries

@media only screen and (max-width:480px) {
	.button .center,
	button {
		display: block;
		margin: 0;
		padding: 0;
		width: 60%;
		margin: 16px auto;
	}
}

@media only screen and (max-width:400px) {
	.button .center,
	button {
		display: block;
		padding: 0;
		width: 96%;
		margin: 16px 2%;
	}
}