// Navigation

$nav_fg_color: lighten($fg_color, 5%);
$nav_height: 64px;
$nav_icon_size: 16px;

nav {
	padding:0;
	z-index: 10;
	width: calc(100% - 16px);
	background-color:$base_color_light;
	padding:8px;
	height:$nav_height - 32px;
	border: 1px solid $border_color;
	border-radius: 9px;

	a,span{
		line-height:$nav_height - 32px;
		&.button {margin:0;}
		> svg {
			fill:$link_color;
		}
	}

	span.prev,span.next{opacity: 0.5;}

	// search icon
	> svg {
		margin: calc((#{$nav_height} - 24px) / 2) 12px;
		margin-right: 0px;
		fill: lighten($nav_fg_color, 20%);
	}

	// search box stylings
	input[type=search]{
		@extend %reset;
		color:$nav_fg_color;
		display: inline-block;
		font-family: $font_family;
		font-size: $font_size + 4px;
		line-height: $font_size + 12px;
		font-weight: 300;
		height: calc($nav_height / 2);
		overflow: hidden;
		padding:calc(#{$nav_height} / 4) 8px;
		padding-right: 12px;
		position: absolute;
		text-overflow: ellipsis;
		vertical-align: baseline;
		white-space: nowrap;
		width: calc(100% - 54px);
		&:focus{
			@extend %reset;
			border: none;
		}
	}

	// just in case
	ul, ol {
		list-style: none;
		list-style-image: none;
	}
}