// Common values
$legacy_browser_support: false !default;

// Fonts
$sans_serif: "soleil", sans-serif;
$monospace: "courier-prime", monospace;
$serif: "pt-serif", serif;

// Fonts
$font_family: $sans_serif;
$font_size: 18px;
$font_weight: 400;

// Main colors
$bg_color: #fafafa;
$fg_color: #444;
$brand_color: #ee0066;

$base_color_dark:darken($bg_color, 5%);
$base_color_light:#fff;
$base_color:$base_color_light;

$highlight_fg_color: #fff;

// primary colors
$light_blue_color:#8866ff;
$blue_color:#0055bb;
$green_color: #00cc77;
$pink_color: #ee0066;
$red_color: #ee1100;
$orange_color: #ee8800;
$yellow_color:#ffee00;

// Link colors
$link_color: $pink_color;
$link_focus_color: $light_blue_color;

// Selection
$selection_bg_color: $link_color;
$selection_fg_color: $highlight_fg_color;

// Borders
$border_color: darken($bg_color, 10%);
// $border_color: rgba(0,0,0,0.1);
$border_radius: 5px;

// Row
$width: 800px;

// Shadows
$text_shadow: 0 1px rgba(255,255,255,0.8);
$text_shadow_dark: 0 -1px rgba(0,0,0,0.3);