// Listed Posts
table.listed-posts {
	background-color:$base_color;
	margin:24px auto;
	padding:12px;
	width:100%;

	> tbody > tr {
		th,
		td {
			border-top:1px solid $border_color;
			border-right:none;
			vertical-align:top;
			padding:8px;

			> a {font: inherit;}

			&:nth-child(1) {
				width:75%;
				text-align: left;
			}
			&:nth-child(2) {
				width:25%; 
				text-align: right;
				font-family:$monospace;
			}
		}
	}
}

@media only screen and (max-width:520px) {
	table.listed-posts {
		> tbody > tr {
			th,
			td {
				&:nth-child(1) {width:100%;}
				&:nth-child(2) {display:none;}
			}
		}
	}
}