// article a.k.a. blog post stylings
article {
	margin: 2em auto;
	width:100%;
	display:inline-block;


	// title
	.title {
		font-size: $font_size + 23px !important;
		line-height: 1.25 !important;
	}

	// subtitle
	.subtitle {
		margin: 12px 0;
		line-height: 1.25 !important;
		font-style: italic;
	}
	
	// paragraph style
	p:not(.infobar),
	p i,
	p b,
	p strong,
	ul:not(.levelbars),
	ol,
	li a,
	p a:not(.button){
		font-family: $serif;
		font-size: $font_size + 1px;
	}

	// drop cap style for posts

	// p.drop-cap,
	// > p:first-of-type {
	// 	&::first-letter {
	// 		float: left;
	// 		font-size: 48px;
	// 		line-height: 1;
	// 		font-weight: 700;
	// 		padding-right: 6px;
	// 		color: inherit;
	// 	}
	// }

	// Separator
	> hr {
		border:none;
		display:block;
		width: 100%;
		text-align: center;
		font-size:80%;
		color:darken($bg_color, 10%);
		line-height: 36px;
		height: 36px;
		&:after{
			content:"✤✤✤";
			letter-spacing: 8px;
		}
	}

	// blockquote
	blockquote {

		box-shadow: inset 5px 0 0 0 $border_color;

		> p {
			margin: 0 2em;
			font-size: $font_size + 2px;
			line-height: $font_size + 6px;
			font-family: $sans_serif !important;
			&:first-letter {text-transform: uppercase;}
		}

		&.block {
			background-color: white;
			width: 100%;
			margin: 2em auto;
			padding: 1em 0;
			box-shadow: inset 5px 0 0 0 $link_color,inset 0 0 0 1px $border_color;
			border-radius: 0 5px 5px 0;
			> p {
				width: calc(100% - 4em) !important;
				margin: 0 2em;
				&:before {content:'“';opacity: 0.5;}
				&:after {content:'”';opacity: 0.5;}
				font-family: $sans_serif !important;
				// font-size: $font_size - 1px;
			}
		}
	}


	// post images
	video, img {
		margin: 32px auto;
		border-radius: 7px;
	}

}

// annoyingly firefox needs a margin
@-moz-document url-prefix() { article>p:first-of-type:first-letter {margin-top: 10px;}}