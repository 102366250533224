// Footer
$footer_height: 128px;
$footer_fg_color: lighten($fg_color, 40%);

// wrapper
.wrapper {
	min-height: 100%;
	margin-bottom: calc((#{$footer_height} + 32px) * -1);
}

.push {
	height: calc(#{$footer_height} + 32px);
}

footer {
	padding-top: 32px;
	min-height: $footer_height;
	text-align: center;

	#year {
		font: inherit;
		color: inherit;
		display: inline !important;
		&:before {
			padding:0;
			content: "";
		}
	}

	a,
	span {
		display: inline;
		font-family: $monospace;

		&:before {
			color: $footer_fg_color;
			content: "•";
			padding:0 1em;
		}

		&:nth-child(1):before {
			content: "";
		}
	}

	a {
		display: inline-block;
		text-decoration: none;

		&:hover {
			color: $footer_fg_color;
		}
	}

	span {
		color: $footer_fg_color;
	}

	.eh {
		margin: 2em 0;
		width: 100%;
		display: block;
		text-align: center;

		&:before {
			content: "";
		}
	}

}

// MEDIA QUERIES
@media only screen and (max-width:848px) {

	footer {
		a,
		span {
			clear: both;
			display: block;
			text-align: left;
			float: left;
			margin: 12px 8px;
			&:before {
				content: "";
				padding: 0;
			}
		}
	}
}