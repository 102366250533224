// Header
$header_fg_color: darken($fg_color, 10%);
$header_height: 64px;
$header_padding: 24px;
$header_font_size: 48px;

header {
	min-height:$header_height - 64px;
	padding:$header_padding 0;
	width: 100%;

	a, span {
		line-height: $header_height;
		font-size: $header_font_size;
		font-weight:bold;
		display: inline-block;
	}

	span {
		color:$header_fg_color;
		width: 100%;
		&.subtitle {
			font-size: $header_font_size - 12px;
		}
	}

	a {
		line-height: $header_height;
		text-decoration: underline;

		&.arrow {
			font-size: $header_font_size + 4px;
			line-height: $header_font_size;
			text-decoration: none;
			font-weight: 300;
			margin-left: -54px;
			padding-right: 8px;
			vertical-align: super;

			&:hover,
			&:focus {
				color: transparentize($header_fg_color, 0.5);
				transform:translateX(-8px);
			}
		}
	}
}

// MEDIA QUERIES
@media only screen and (max-width:848px) {
	header {
		a, span { font-size: $header_font_size - 8px; }
		a.arrow { margin:0; font-size: $header_font_size; }
	}
}