// Timetable
table.timetable {
	background-color:$base_color;
	border-collapse: collapse;
	border-spacing: 0;
	border:1px solid $border_color;
	font-family:$monospace;
	font-size:$font_size;
	font-weight:400;
	margin:12px auto;
	padding:12px;
	text-align:left;
	width:100%;

	> thead > tr,
	> tbody > tr {

		th,
		td {

			&:nth-child(1) {width:20%;}
			&:nth-child(2) {width:20%;}
			&:nth-child(3) {width:60%;}
		}
	}

	&.thirds {
			> thead > tr,
		> tbody > tr {

			th,
			td {
				&:nth-child(1) {width:35%;}
				&:nth-child(2) {width:20%;}
				&:nth-child(3) {width:45%;}
			}
		}
	}

	&.no-time {
			> thead > tr,
		> tbody > tr {

			th,
			td {

				&:nth-child(1) {width:20%;}
				&:nth-child(2) {width:80%;}
			}
		}
	}
}