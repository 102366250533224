// Tables
$table_bg_color: lighten($bg_color, 5%);

table {
	background-color:$table_bg_color;
	border-collapse: collapse;
	border-spacing: 0;
	border:1px solid $border_color;
	// font-family:$monospace;
	font-size:$font_size;
	font-weight:400;
	margin:1em auto;
	padding:1em;
	text-align:left;
	width:100%;

	> thead {
		font-weight:bold;
		border:none;
		> tr {
			background-color:$table_bg_color;
		}
	}

	> tbody,
	> thead {
		> tr {

			th,
			td {
				border-top:1px solid $border_color;
				border-right:1px solid $border_color;
				vertical-align:top;
				padding:8px 12px;

				> a {font: inherit;}
			}
		}
	}

	// alternate bg color of table rows
	> tbody {
		> tr {
			&:nth-child(even) {background-color:darken($table_bg_color, 0%);}
			&:nth-child(odd) {background-color:$table_bg_color;}
		}
	}
}