// headings
$headings_padding: 8px;
$headings_margin: 12px;

h1, h2, h3, h4, h5, h6 {
	@extend %text;
	// margin:0;
	padding:$headings_padding 0;
	font-weight: bold;
	color: darken($fg_color, 20%);
	> a {
		font: inherit;
	}
}

h1, h2 {
	color: darken($fg_color, 30%);
}

h3, h4 {
	color: darken($fg_color, 10%);
}


h4, h5, h6 {
	font-weight: normal;
	color: lighten($fg_color, 10%);
}

h1 {
	font-size:$font_size + 16px ;
	line-height:1.5;
	margin:$headings_margin 0;
}

h2 {
	font-size:$font_size + 11px ;
	line-height:1.5;
	margin:$headings_margin 0;
}

h3 {
	font-size:$font_size + 7px;
	line-height:1.5;
	margin:$headings_margin 0;
}

h4 {
	font-size:$font_size + 7px;
	line-height:1.5;
	margin:$headings_margin 0;
}

h5 {
	font-size:$font_size + 4px;
	line-height:1.5;
	margin:$headings_margin 0;
}

h6 {
	font-size:$font_size + 2px;
	line-height:1.5;
	margin:$headings_padding 0;
}
