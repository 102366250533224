// Tiles

$tile_bg_color: $base_color;
$tile_border_radius: 7px;
$tile_fg_color: $fg_color;
$tile_font_size: 24px;
$tile_height: 96px;
$tile_logo_size: 48px;
$tile_margin: 8px;
$tile_padding: 10px;
$tile_width: 200px;

.tile {
	background-color:$tile_bg_color;
	border-radius: $tile_border_radius;
	border: 1px solid rgba(0,0,0,0.15);
	box-shadow: 0 1px 2px 0 rgba(0,0,0,0.1);
	color: $tile_fg_color;
	display: inline-block;
	font-size: $tile_font_size;
	font-weight: 500;
	height:$tile_height;
	line-height: $tile_height;
	margin: $tile_margin;
	outline: none;
	text-align: center;
	text-decoration: none;
	vertical-align: middle;
	width: $tile_width;

	&.long {
		height:$tile_height - 48px;
		line-height: $tile_height - 48px;
		width: 46%;
		font-size: $tile_font_size - 4px;
	}

	// Image
	> img {
		display: block;
		vertical-align: middle;
		margin:0 auto;
	}

	// Interaction
	&:focus,
	&:hover {
		color: inherit;;
		border-color: transparent;
		transform: translateY(-4px);
		@include transition(200ms);
	}
	
	&:hover {
		box-shadow: 0 2px 4px 0 rgba(0,0,0,0.4), 0 3px 8px 0 rgba(0,0,0,0.1);
	}
	
	&:focus {
		box-shadow: 0 1px 2px 0 rgba(0,0,0,0.2),0 2px 4px 0 transparentize($link_color,0.7);
	}

	&:active {
		box-shadow: 0 1px 2px 0 rgba(0,0,0,0.2);
		transform: translateY(1px);
		@include transition(200ms);
	}

	// With logo
	&.logo {
		> svg {
			height: $tile_logo_size;
			width: $tile_logo_size;
			margin-top: calc((#{$tile_height} / 2) - (#{$tile_logo_size} / 2))
		}

		> svg {
			fill: $base_color;
		}
	}
}

// MEDIA QUERIES

@media only screen and (max-width:600px) {

	.tile {
		margin: 4px 2px;
		width: 45%;
		> span {
			font-size: calc(#{$tile_font_size} * 0.8);
			line-height: calc(#{$tile_font_size} * 0.8);
			width: 100%;
		}

		> svg {
			height: calc(#{$tile_logo_size} * 0.75);
			margin-top: calc((#{$tile_height} / 2) - ((#{$tile_logo_size} * 0.75) / 2))
		}

		&.long {
			width: 100%;
			> span {
				font-size: $tile_font_size - 4px;
				margin-top: 12px;
			}
		}
	}
}