// Notes

// $notes_bg_color: #F6F3EC;
$notes_bg_color: $base_color_light;
$notes_accent_color: $link_color;
$notes_title: "Notes";
$notes_width: 480px;

// Overall table style
table.notes {
	background-color: $notes_bg_color;
	border-radius: 0 5px 5px 0;
	border:1px solid rgba(0,0,0,0.08);
	border-left-width: 0;
	box-shadow: -5px 0 0 0 $notes_accent_color, inset 0 1px 0 0 rgba(255,255,255,0.4),0 1px 2px 0 rgba(0,0,0,0.05);
	display: inline-block;
	font-family: $monospace;
	color: darken($fg_color, 20%);
	font-size: $font-size;
	margin: 12px 0;
	max-width: $notes_width;
	padding: 0;
	padding-bottom: 2px;

	// text styles
	> thead {
		> tr {
			background-color: transparent;
			border-bottom: 1px dashed darken($notes_bg_color, 10%);
			> th {
				border: none;
				padding: 6px 0 4px 0;
				vertical-align: top;
				padding-right: 8px;
				background-color: transparent;

				> a {font-family: inherit;}

				&:nth-child(odd) {
					width: $notes_width * 0.3 - 8px;
					padding-left: 8px;
				}

				&:nth-child(even) {
					width: $notes_width * 0.7 - 32px;
					padding-right: 32px;
				}
			}
		}
	}
	// text styles
	> tbody {

		> tr {
			background-color: transparent;
			border-bottom: 1px dashed darken($notes_bg_color, 10%);

			// &:first-child {border-top:1px dashed darken($notes_bg_color, 10%);}
			&:first-child {border-top:none;}
			&:last-child {border:none;}

			> td {
				border: none;
				padding: 6px 0 4px 0;
				vertical-align: top;
				padding-right: 8px;
				background-color: $notes_bg_color !important;

				> a {font-family: inherit;}

				&:nth-child(odd) {
					width: $notes_width * 0.3;
					color: lighten($fg_color, 30%);
					text-align: right;

					// add a separator
					&:after {content: ":"}
				}

				&:nth-child(even) {
					width: $notes_width * 0.7 - 32px;
					padding-right: 32px;
				}
			}
		}
	}
}

@media only screen and (max-width:480px) {table.notes{margin-left:calc(-5% + 2px);}}