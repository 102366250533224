// Page Separator

.separator {
	border:none;
	display:block;
	width: 100%;
	text-align: center;
	font-size:125%;
	color:darken($bg_color, 10%);
	margin: 24px 0;
	line-height: 24px;
	height: 24px;
	&:after{content:"✤";}
}