// Levelbars

ul.levelbars {
	display: block;
	padding: 1em;
	margin: 0;
	list-style: none;

	> li {
		display: inline-block;
		height: 1em;
		border-radius: 0.5em;
		width: 100%;
		padding: 0;
		margin: 1.5em auto 0.5em auto;
		background-color: rgba(0,0,0,0.1);
		box-shadow: inset 0 1px 2px 0 rgba(0,0,0,0.1), 0 1px 0 0 rgba(255,255,255,0.8);

		> span {
			display: inline-block;
			float: left;
			line-height: 1.2em;
			font-size: 1.125em;
			margin-top:-1.5em;
			text-transform: uppercase;
			color: #444;

			&.right {
				font-weight: bold;
				float: right;
			}
		}
	}
}

.level{
	border-top-left-radius: 0.5em;
	border-bottom-left-radius: 0.5em;
	box-shadow: inset 0 1px 1px 0 rgba(0,0,0,0.1);
	height: 1em;

	&.l1 {
		width:10%;
		background-color: #f34235;
	}

	&.l2 {
		width:20%;
		background-color: #ff5722;
	}

	&.l3 {
		width:30%;
		background-color: #fe9700;
	}

	&.l4 {
		width:40%;
		background-color: #fec006;
	}

	&.l5 {
		width:50%;
		background-color: #feea3a;
	}

	&.l6 {
		width:60%;
		background-color: #ccdb38;
	}

	&.l7 {
		width:70%;
		background-color: #8ac249;
	}

	&.l8 {
		width:80%;
		background-color: #4bae4f;
	}

	&.l9 {
		width:90%;
		background-color: #1ea362;
	}

	&.l10{
		border-radius: 0.5em;
		width:100%;
		background-color: #009DAF;
	}
}